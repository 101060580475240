<template>
    <div class="catalogues__list">
        <button class="catalogues__list__button" v-b-modal.list-branch-1>
            Sucursales
        </button>
        <b-modal id="list-branch-1" title="Sucursales" ok-only scrollable>
            <div class="my-4" v-for="branch in getBranches" :key="branch.id" target="_blank">
                <p>{{ branch.name }} - {{ branch.phone }}

                    <b-icon @click="deleteBranchHelper(branch.id)" icon="x"
                    :style="{ color: 'red', cursor: 'pointer' }"></b-icon>
                </p>
            </div>
        </b-modal>
    </div>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    computed: {
        ...mapGetters("vCard", ["getBranches"]),
    },

    methods: {
        async deleteBranchHelper(id) {
            try {
                await this.deleteBranch(id);
                this.$toast.success("Se elemino correctamente");
            } catch (error) {
                this.$toast.error("Ocurrio un error al borrar la sucursal");
            }
        },
        ...mapActions("vCard", ["deleteBranch"]),
    },

};
</script>
  
<style>
</style>
  